<template>
  <div class="backViewCss">
    <div class="bannerleftCss">
      <swiper v-if="bannerArr.length"  :options="swiperOption" ref="mySwiper">
        <swiper-slide  v-for="(item, index) in bannerArr" :key="index">
          <div style="position: relative" @click="openNewPage(item)">
            <img class="imgCss" :src='item.backPic' alt="">
            <div style="width: 100%;height: 58px;line-height: 58px;background-image: linear-gradient(-180deg, rgba(238,238,238,0.00) 0%, #000000 99%);;color: #fff;position: absolute;left: 0;bottom: 5px;text-align: left;padding-left: 10px;font-size: 18px">
              {{ item.newsTitle }}
            </div>
          </div>
        </swiper-slide>
        <div id="swiper-pagination" class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="gonggao-box">
      <div style="height: 56px;width: 100%;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;">
        <div style="display: flex;align-items: center">
        <img src="../assets/laba.png" style="width: 40px;margin-right: 20px" alt="">
        <span style="font-size: 18px">公告</span>
        </div>
        <div class="moreBtn" @click="toNetMessage">更多</div>
      </div>
      <div style="width: 100%;">
        <div v-for="(item, index) in webMessageList" @click="toNetMessageDetail(item)" :key="index" class="webMessage-item">
          <div class="webMessage-item-title">{{ item.messageTitle.substring(0, 20) }}</div>
          <div style="display: flex;justify-content: space-between;align-items: baseline;margin-top: 3px">
            <div class="webMessage-item-title2">{{ item.messageTitle.substring(20) }}</div>
            <div class="webMessage-item-subTitle">{{ item.createTime.substring(0, 10) }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="bannerrightCss">-->
      <!--<div style="height: 56px;width: 100%;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;">-->
        <!--<div style="display: flex;align-items: center">-->
          <!--<img src="../assets/laba.png" style="width: 40px;margin-right: 20px" alt="">-->
          <!--<span style="font-size: 18px">公告</span>-->
        <!--</div>-->
        <!--<div class="moreBtn" @click="toDongtai">更多</div>-->
      <!--</div>-->
      <!--<div class="lineTitleCss" v-for="(item, index) in bannerArr" @mouseleave="leave(index)" @mouseenter="enter(index)">-->
        <!--<div @click="openNewPage(item)">-->
          <!--<div class="pointCss"></div>-->
          <!--<div class="titleCss" :id="'title' + index">{{item.newsTitle}}</div>-->
          <!--<div style="font-size: 13px;color: #999;text-align: right;width: 100%">{{ item.createTime }}</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>

  import 'swiper/dist/css/swiper.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'

  export default {
    name: "HomeBannerView",
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        host : window.location.host,
        bannerArr : [],
        bannerAdList:[],
        webMessageList: [],
        lastIndex : 0,
        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          loop: true,
          speed: 2500,
          observer:true,
          observeParents:true,
          watchSlidesProgress: true,
          autoplay: {
            delay:2500,
          },
          on:{
            // transitionEnd:()=>{
            //   let swiper = this.$refs.mySwiper.swiper;
            //   var i = 0;
            //   if (swiper ){
            //     i = swiper.activeIndex - 1;
            //   }
            //   if (i >= this.bannerArr.length)
            //   {
            //     i = 0;
            //   }
            //   var lastItem=document.getElementById('title'+this.lastIndex);
            //   lastItem.style.color = "#080808";
            //   this.lastIndex = i
            //   var nowItem=document.getElementById('title'+i);
            //   nowItem.style.color = "#409EFF";
            // },
          }
        },
      }
    },
    computed:{
      mySwiper(){
        return this.$refs.mySwiper.swiper
      }
    },
    methods: {
      toNetMessage() {
        this.$router.push({
          name: 'netTransmit'
        })
      },
      toNetMessageDetail(item) {
        this.$router.push({
          name: 'netTransmitDetail',
          params: {
            id: item.id
          }
        })
      },
      selectWebMessage() {
        let { department, createTime } = this
        this.$api.home.selectWebMessage({
          offset: 0,
          limit: 5,
          department,
          createTime,
        }).then(res => {
          console.log('222', new Date())
          let { code, result } = res
          if (code == 200) {
            this.webMessageList=result.rows
          }
        })
      },
      getData(){
        this.$api.home.getStudyNews({
          displayLocation: '首页轮播',
        }).then(res => {
          let { code, result } = res
          if (code == 200) {
            this.bannerArr = result.rows.slice(0, 5)
            // this.bannerAdList = res.data.data.bannerList.slice(0,8);
            // this.getAdData(this.bannerAdList[0].channelTemplateId);
          }
        })
      },
      getAdData(channelTemplateId){
        this.$api.v1Api.adeGtShow({
            type: 4,
            channelTemplateId: channelTemplateId,
          }
        ).then(res => {
          if (res.data.code == 200) {
            if (res.data.data.length > 0){
              var item = res.data.data[0];
              item.showType = 1;
              if (item.location > this.bannerAdList.length){
                this.bannerAdList.splice(8,0,item);
              }
              else {
                this.bannerAdList.splice(item.location,0,item);
              }
            }
            this.bannerArr = this.bannerAdList.slice(0,8);
          }
        }).catch(reason => {
          this.bannerArr = this.bannerAdList.slice(0,8);
        })
      },
      enter(index){
        var lastItem=document.getElementById('title'+this.lastIndex);
        lastItem.style.color = "#080808";
        this.lastIndex = index
        var nowItem=document.getElementById('title'+index);
        nowItem.style.color = "#409EFF";
        this.mySwiper.autoplay.stop();
        this.mySwiper.slideTo(index+1);
      },

      leave(index){
        this.mySwiper.autoplay.start();
      },
      openNewPage(item) {
        this.$router.push({
          name: 'yxdongtaiDetail',
          params: {
            id: item.id
          }
        })
      },
      adAddCount(orderId) {
        this.$api.v1Api.adAddCount({
            id:orderId
          }
        ).then(res => {
        })
      },
      toDongtai() {
        this.$router.push({
          name: 'yxdongtai'
        })
      }
    },

    mounted() {
      this.getData();
      this.selectWebMessage()
    }
  }
</script>

<style lang="scss" scoped>
  .backViewCss{
    width: 1200px;
    margin: auto;
    background-color: #f7f7f7;
    display: flex;
    padding-top: 34px;
    padding-left: 8px;
    padding-right: 8px;
    justify-content: space-between;
    z-index: 6;
  }

  .bannerleftCss{
    width: 686px;
    height: 378px;
    display:inline-block;
    vertical-align: top;
  }

  .imgCss{
    width: 686px;
    height: 378px;
    cursor:pointer;
  }

  .bannerrightCss{
    width: 478px;
    height: 387px;
    padding: 0 20px;
    background-color: #FFFFFF;
    display:inline-block;
    vertical-align: top;
    text-align: left;
  }

  .lineTitleCss{
    width: 100%;
    /*height: 44px;*/
    /*margin-bottom: 1px;*/
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
    cursor: pointer;
  }
  .lineTitleCss:hover .titleCss {
    color: #409EFF;
  }
  .pointCss{
    width: 4px;
    height: 4px;
    background-color: #959595;
    border-radius: 1px;
    display:inline-block;
    margin-left: 25px;
    margin-top: 20px;
    position:absolute;
  }

  .titleCss{
    width: 100%;
    font-size: 16px;
    color: #080808;
    display:inline-block;
    padding: 12px 0 0 40px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    cursor:pointer;
  }
.moreBtn {
  font-size: 14px;
  cursor: pointer;
}
  .moreBtn:hover {
    color: #409EFF;
  }
  .gonggao-box {
    width: 478px;
    height: 387px;
    padding: 0 20px;
    background-color: #FFFFFF;
    display:inline-block;
    vertical-align: top;
    text-align: left;
  }
  .webMessage-item:hover {
    .webMessage-item-title2 {
      color: #2C9FE8;
    }
    .webMessage-item-title {
      color: #2C9FE8;
    }
  }
  .webMessage-item {
    padding: 5px 0;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    .webMessage-item-title {
      font-size: 16px;
      color: #1E1E1E;
    }
    .webMessage-item-title2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #1E1E1E;
      font-size: 16px
    }
    .webMessage-item-subTitle {
      width: 150px;
      text-align: right;
      font-size: 14px;
      color: #767676;
    }
  }
</style>
