<template>
  <div id="thediv" ref="thediv"
       v-show="thedivShow" @mouseover="clearFdAd" @mouseout="starFdAd">
    <div style="font-size: 18px;padding-top: 15px;display: flex;">
      <div style="flex: 1;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{ advObj.messageTitle }}</div>
      <img style="width: 15px;height: 15px;float: right;margin-right: 10px;cursor: pointer" @click="closeThe" src="../assets/icon_close.png" alt="">
    </div>
    <div @click="toDetail(advObj)" class="content" style="font-size: 14px;cursor: pointer" v-html="advObj.messageContent"></div>
  </div>
</template>

<script>
  var interval;
  export default {
    data() {
      return {
        xPos: 0,
        yPos: 0,
        xin: true,
        yin: true,
        step: 1,
        delay: 20,
        height: 0,
        Hoffset: 0,
        Woffset: 0,
        yon: 0,
        xon: 0,
        pause: true,
        thedivShow: false,
        advObj: {
          id: '',
          messageTitle: '',
          messageContent: ''
        }
      };
    },

    created() {
      this.$api.home.selectWebMessage({
        offset: 0,
        limit: 1,
        isFloat: '1',
      }).then(res => {
        if (res.code == 200) {
          if (res.result.rows.length > 0) {
            Object.assign(this.advObj, res.result.rows[0])
            this.thedivShow = true
            interval = setInterval(this.changePos, this.delay)
          } else {
            this.thedivShow = false
            clearInterval(interval)
          }
        }
      })
    },

    methods: {
      toDetail(item) {
        this.$router.push({
          name: 'netTransmitDetail',
          params: {
            id: item.id
          }
        })
      },
      closeThe() {
        this.thedivShow = false
        clearInterval(interval)
      },
      changePos() {
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        this.Hoffset = 184; //获取元素高度
        this.Woffset = 320;
        // 滚动部分跟随屏幕滚动
        this.$refs.thediv && (this.$refs.thediv.style.left = (this.xPos + document.body.scrollLeft + document.documentElement.scrollLeft) + "px")
        this.$refs.thediv && (this.$refs.thediv.style.top = (this.yPos + document.body.scrollTop + document.documentElement.scrollTop) + "px")

        // 滚动部分不随屏幕滚动
        // this.$refs.thediv.style.left = (this.xPos + document.body.scrollLeft) + "px";
        // this.$refs.thediv.style.top = (this.yPos + document.body.scrollTop) + "px";

        if (this.yon) {
          this.yPos = this.yPos + this.step;
        } else {
          this.yPos = this.yPos - this.step;
        }
        if (this.yPos < 0) {
          this.yon = 1;
          this.yPos = 0;
        }
        if (this.yPos >= (height - this.Hoffset - 50)) {
          this.yon = 0;
          this.yPos = (height - this.Hoffset -50);
        }

        if (this.xon) {
          this.xPos = this.xPos + this.step;
        } else {
          this.xPos = this.xPos - this.step;
        }
        if (this.xPos < 0) {
          this.xon = 1;
          this.xPos = 0;
        }
        if (this.xPos >= (width - this.Woffset)) {
          this.xon = 0;
          this.xPos = (width - this.Woffset);
        }
      },
      clearFdAd() {
        clearInterval(interval)
      },
      starFdAd() {
        interval = setInterval(this.changePos, this.delay)
      },
    }
  }
</script>

<style lang="scss" scoped>

  #thediv {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    height: 184px;
    width: 320px;
    background: rgba(0,0,0,.5);
    color: #fff;
    .content {
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3;
      text-align: left;
      padding: 0 10px;
      p {
        margin: 0!important;
      }
    }
  }
</style>